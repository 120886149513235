@tailwind base;
@tailwind components;
@tailwind utilities;

input::-ms-reveal,
input::-ms-clear {
  display: none;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset;
  box-shadow: 0 0 0 1000px white inset;
}

body {
  font-smooth: never;
}
